import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0c79acd8"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "app"
};
import theNavBar from '@/components/header/nav.vue';
import Loading from '@/components/loading.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import router from './router';
export default {
  __name: 'App',

  setup(__props) {
    const store = useStore();
    const appTitle = computed(() => store.getters['common/appTitle']);
    console.log(router.currentRoute.value.meta.hidBar);
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_unref(router).currentRoute.value.meta.hidBar ? (_openBlock(), _createBlock(theNavBar, {
        key: 0,
        title: _unref(appTitle)
      }, null, 8, ["title"])) : _createCommentVNode("", true), _createVNode(_component_router_view), _createVNode(Loading)]);
    };
  }

};