import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-05a90b3e"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "home"
};
import { onMounted } from 'vue';
import { isLocalStorageAvailable } from '@/utils/tool';
export default {
  __name: 'HomeView',

  setup(__props) {
    onMounted(() => {
      isLocalStorageAvailable();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, "网页走丢了");
    };
  }

};