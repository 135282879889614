import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/reset.less';
import '@/assets/css/animate.css';
import { Toast } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import { Lazyload } from 'vant';

createApp(App).use(store).use(router).use(Toast).use(Lazyload).mount('#app');
