interface StateType {
    name: string;
    loading: boolean;
    appTitle: string;
    helperZhiliActive: Number;
}
export default {
    state: {
        name: 'common',
        loading: false,
        appTitle: '',
        helperZhiliActive: 0
    },
    getters: {
        loading(state: StateType): boolean {
            return state.loading;
        },
        appTitle(state: StateType): string {
            return state.appTitle;
        },
        helperZhiliActive(state: StateType): Number {
            return state.helperZhiliActive;
        }
    },
    mutations: {
        updateLoading(state: StateType, playLoad: boolean): void {
            state.loading = playLoad;
        },
        changeAppTitle(state: StateType, str: string): void {
            state.appTitle = str;
        },
        changeHelperZhiliActive(state: StateType, no: number): void {
            state.helperZhiliActive = no;
        }
    },
    actions: {},
    modules: {},
    namespaced: true
};
