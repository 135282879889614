import { createStore } from 'vuex';
import common from './common';
export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        common
    }
});
